var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"floats_right"},[_c('a',{on:{"click":()=>{
      _vm.contentHtml = _vm.textInfo.rule
      _vm.contentHtmlModal = true
    }}},[_c('img',{attrs:{"src":_vm.oss+'ldy_rule.png'}})]),_c('router-link',{attrs:{"to":"/service"}},[_c('img',{attrs:{"src":_vm.oss+'ldy_service.png'}})])],1),_c('img',{staticClass:"logo",attrs:{"src":_vm.oss+'logo.png'}}),_c('img',{staticClass:"bg",attrs:{"src":_vm.oss+'light_bg2.png'}}),_c('div',{staticClass:"cover-image"},[_c('img',{attrs:{"src":_vm.oss+'ldy_xz.png'}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"tip-c"},[_c('span',[_vm._v(_vm._s(_vm.money.price)+"充值会员专享")])]),_c('div',{staticClass:"fonts"},[_c('label',[_vm._v("￥")]),_c('count-to',{attrs:{"start-val":20,"end-val":200,"duration":3000}})],1),_c('div',{staticClass:"mobile-form"},[_c('div',{staticClass:"phone"},[_c('van-field',{ref:"phone",attrs:{"type":"tel","border":false,"placeholder":"请输入手机号码充值"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"class-prefix":"icon","name":"mobile","size":"20","color":"#888"}})]},proxy:true}]),model:{value:(_vm.json.phone),callback:function ($$v) {_vm.$set(_vm.json, "phone", $$v)},expression:"json.phone"}})],1),_c('div',{staticClass:"paybtn"},[_c('img',{attrs:{"src":_vm.oss+'ldy_button.png'},on:{"click":_vm.onsubmit}})]),_c('div',{staticClass:"tips"},[_c('van-checkbox',{attrs:{"icon-size":"16px","checked-color":"#52c100"},model:{value:(_vm.isread),callback:function ($$v) {_vm.isread=$$v},expression:"isread"}},[_vm._v("我已阅读并同意")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.privacy_policy
          _vm.contentHtmlModal = true
        }}},[_vm._v("《隐私协议》")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.user_agreement
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户协议》")])],1)])]),_vm._m(0),(_vm.contentHtmlModal)?_c('van-popup',{staticClass:"contentPop",attrs:{"overlay-style":{backgroundColor:'rgba(0,0,0,.5)'},"close-on-click-overlay":false},model:{value:(_vm.contentHtmlModal),callback:function ($$v) {_vm.contentHtmlModal=$$v},expression:"contentHtmlModal"}},[_c('div',{staticClass:"html",domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('div',{staticClass:"close"},[_c('van-button',{staticStyle:{"width":"60%"},attrs:{"type":"primary","round":"","color":"#52c100"},on:{"click":()=>{_vm.contentHtmlModal = false}}},[_vm._v("确定")])],1)]):_vm._e(),(_vm.money.price)?_c('payment',{ref:"payment",attrs:{"data":_vm.json,"money":_vm.money,"showRetention":1}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('p',[_vm._v("客服电话：4006178158")]),_c('p',[_vm._v("南通有钻贸易有限公司")])])
}]

export { render, staticRenderFns }