<template>
  <div class="container">
    <div class="floats_right">
      <a @click="()=>{
        contentHtml = textInfo.rule
        contentHtmlModal = true
      }"><img :src="oss+'ldy_rule.png'"/></a>
      <router-link to="/service"><img :src="oss+'ldy_service.png'"/></router-link>

    </div>
    <img :src="oss+'logo.png'" class="logo"/>

    <img :src="oss+'light_bg2.png'" class="bg"/>
    <div class="cover-image"><img :src="oss+'ldy_xz.png'"/></div>
    <div class="main">
      <div class="tip-c"><span>{{money.price}}充值会员专享</span></div>
      <div class="fonts"><label>￥</label><count-to :start-val="20" :end-val="200" :duration="3000"></count-to></div>
      <div class="mobile-form">
      <div class="phone">
        <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入手机号码充值">
          <template #left-icon>
            <van-icon class-prefix="icon" name="mobile" size="20" color="#888"/>
          </template>
        </van-field>
      </div>
      <div class="paybtn"><img :src="oss+'ldy_button.png'" @click="onsubmit"/></div>

      <div class="tips">
        <van-checkbox v-model="isread" icon-size="16px" checked-color="#52c100">我已阅读并同意</van-checkbox>
        <a @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《隐私协议》</a>
        <a @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>
      </div>
      </div>
    </div>

    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>南通有钻贸易有限公司</p>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#52c100" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <payment :data="json" :money="money" ref="payment" v-if="money.price" :showRetention="1"/>
  </div>
</template>
<script>
  import countTo from 'vue-count-to'
  import moment from 'moment'
  import payment from './components/payment'
  import {GetPayConfig,GetUserPrivate} from './services/services'
  export default {
    name:'pink',
    components: {countTo,payment},
    data () {
      return {
        money:{},
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
        timer:null
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }

      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    mounted(){
      this.findpayConfig()
      this.finduserprivate()
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      moment,
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = this.detail = result.data.data
            let payWay = this.detail.payConfig[0].payWay
            let pay=this.detail.payConfig.find(item=>item.payWay == payWay)
            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.status==1)
            this.money=money

            this.json.id=money.id

            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$refs.payment.onsubmit(this.json)

      }

    }
  }

</script>
<style scoped lang="less">
  .container{width:100%;min-height:100vh;background:#ffc0cd;position:relative;overflow:hidden;
    .logo{width:40px;position:absolute;left:14px;top:14px;z-index:99;}
    .bg{width:100%;height: 100%;-webkit-transform:scale(1.8,1);-ms-transform:scale(1.8,1);transform:scaleX(1.8);position:absolute;z-index:0;}
    .floats_right{position:fixed;right:0px;top:12px;z-index:9;width:26px;
      img{width:100%;}
      a{margin:8px 0px;display:inline-block;}
    }

    .cover-image{position:absolute;top:50px;left:0px;width:100%;display:block;overflow:hidden;
      img{width:100%;-webkit-animation:ro 4s linear infinite;animation:ro 4s linear infinite;}
    }
    .main{position:relative;background:url('@{static-dir}pink.png') no-repeat center 20px;background-size:100% auto;height:620px;
      .tip-c{color:hsla(0,0%,100%,.5);text-align:center;padding:16px 0px 0px 0px;font-size:12px;
        span{border:1px solid hsla(0,0%,100%,.5);border-radius:4px;display:inline-block;padding:4px 10px;font-size:14px;}
      }
      .fonts{margin-top:210px;text-align:center;color:#ff0052;font-weight:700;display:flex;justify-content:center;height:160px;line-height:160px;background:linear-gradient(270deg,#ff580f,#ff1b24 55%,#ff136a);color:transparent;-webkit-background-clip:text;
        label{padding-top:30px;font-size:36px;margin-right:-4px;}
        span{font-size:110px;font-weight:700;}
      }
      .mobile-form{padding:0px 56px;margin-top:24px;}
      .phone{
        .van-cell{border-radius:50px;border:1px solid red;font-size:16px;line-height:30px;padding:8px 10px 6px 10px;}
      }
      .paybtn{margin-top:10px;text-align:center;
        img{width:90%;-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;}
      }
      .tips{margin-top:8px;font-size:12px;display:flex;
        span{display:inline-block;}
        a{color:#ff8400;}
        /deep/.van-checkbox__label{font-size:12px;color:#fff;}
      }
    }
    .foot{color:#fff;text-align:center;line-height:24px;padding-bottom:12px;font-size:12px;}
  }

  @keyframes paybtn_scalc{
    0% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
    50% {
      -webkit-transform:scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes ro{
    0%{
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }

</style>
